<template>
  <div :class="'iq-sidebar  sidebar-default ' + variant">
    <div
      class="iq-sidebar-logo d-flex align-items-center justify-content-between"
    >
      <a href="/" class="header-logo">
        <img
          src="@/assets/images/favicon.png"
          class="img-fluid rounded-normal light-logo"
          alt="logo"
        />
        <img
          src="@/assets/images/logo-dark.png"
          class="img-fluid rounded-normal d-none sidebar-light-img"
          alt="logo"
        />
        <span>MySyara</span>
      </a>
      <div class="side-menu-bt-sidebar-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-light wrapper-menu"
          width="30"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="miniSidebar"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="side-menu">
          <li class="px-3 pt-3 pb-2">
            <span class="text-uppercase small font-weight-bold"
              >Workshop</span
            >
          </li>
          <li
            :class="
              checkActive('layout.order') || checkActive('order.order-view')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link :to="{ name: 'layout.order' }" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </i>
              <span class="ml-2">Orders</span>
            </router-link>
          </li>

          <li
            :class="
              checkActive('pending-acceptance')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link :to="{ name: 'pending-acceptance' }" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </i>
              <span class="ml-2">Pending Acceptance</span>
            </router-link>
          </li>
          <li
            :class="
              checkActive('approved-work-orders')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link
              :to="{ name: 'approved-work-orders' }"
              class="svg-icon"
            >
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                </svg>
              </i>
              <span class="ml-2">Approved Work Orders</span>
            </router-link>
          </li>
          <li
            :class="
              checkActive('job-timeline')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
            title="Job Timeline"
          >
            <router-link :to="{ name: 'job-timeline' }" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
              </i>
              <span class="ml-2">Job Timeline</span>
            </router-link>
          </li>
          <li
            :class="
              checkActive('jobs') ? 'sidebar-layout active' : 'sidebar-layout'
            "
            title="Jobs"
          >
            <router-link :to="{ name: 'jobs' }" class="svg-icon">             
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                </svg>
              </i>
              <span class="ml-2">My Jobs</span>
            </router-link>
          </li>

          <li class="px-3 pt-3 pb-2">
            <span class="text-uppercase small font-weight-bold"
              >Parts</span
            >
          </li>
          <li
            :class="
              checkActive('my-orders') ? 'sidebar-layout active' : 'sidebar-layout'
            "
            title="My Orders"
          >
            <router-link :to="{ name: 'my-orders' }" class="svg-icon">             
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </i>
              <span class="ml-2">My Orders</span>
            </router-link>
          </li>
          <li
            :class="
              checkActive('orders-not-quoted') ? 'sidebar-layout active' : 'sidebar-layout'
            "
            title="Not Quoted"
          >
            <router-link :to="{ name: 'orders-not-quoted' }" class="svg-icon">             
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                </svg>
              </i>
              <span class="ml-2">Not Quoted</span>
            </router-link>
          </li>
          <li
            :class="
              checkActive('orders-partially-quoted') ? 'sidebar-layout active' : 'sidebar-layout'
            "
            title="Partially Quoted"
          >
            <router-link :to="{ name: 'orders-partially-quoted' }" class="svg-icon">             
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
              </i>
              <span class="ml-2">Partially Quoted</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
export default {
  name: "SidebarStyle",
  props: {
    items: Array,
    variant: { type: String },
  },
  data() {
    return {
      userDetails: [],
      homeurl: "",
      dashboards: [
        "layout.dashboard1",
        "layout.dashboard2",
        "layout.dashboard3",
        "layout.customer",
        "layout.product",
        "layout.order",
        "layout.calendar",
      ],
      app: ["app.chat", "app.todo"],
      charts: [
        "chart.amchart",
        "chart.apexchart",
        "chart.highchart",
        "chart.morrischart",
      ],
      user: ["app.user-profile", "app.user-add", "app.user-list"],
      ui: [
        "Ui.avatars",
        "Ui.alerts",
        "Ui.badges",
        "Ui.breadcrumbs",
        "Ui.buttons",
        "Ui.button-groups",
        "Ui.boxshadows",
        "Ui.colors",
        "Ui.cards",
        "Ui.carousels",
        "Ui.grids",
        "Ui.helper-classes",
        "Ui.images",
        "Ui.list-groups",
        "Ui.media-objects",
        "Ui.modals",
        "Ui.notifications",
        "Ui.paginations",
        "Ui.popovers",
        "Ui.progressbars",
        "Ui.typographys",
        "Ui.tabs",
        "Ui.tooltips",
        "Ui.Embed-videos",
      ],
      auth: [
        "auth.login",
        "auth.register",
        "auth.recover-password",
        "auth.confirm-mail",
        "auth.lock-screen",
      ],
      pricing: ["price.pay", "price.pay2"],
      icon: [
        "icon.dripicon",
        "icon.fontawsome",
        "icon.lineawsome",
        "icon.remixicon",
      ],
      error: ["error.404", "error.500"],
      formControls: [
        "controls.form-checkbox",
        "controls.form-layout",
        "controls.form-input",
        "controls.form-radio",
        "controls.form-switch",
        "controls.form-textarea",
        "controls.form-validation",
      ],
      formWidget: [
        "widget.form-datepicker",
        "widget.form-file-uploader",
        "widget.form-quill",
      ],
      table: ["table.basic-table", "table.data-table", "table.edit-table"],
      timeline: ["time.line", "time.line1"],
      extraPages: [
        "price.pay",
        "pages.maintenance",
        "pages.comingsoon",
        "pages.invoices",
        "pages.subscribers",
        "pages.faq",
        "pages.blank-page",
        "pages.icon",
      ],
      pages: [],
      checklistSvg: require("../../../../assets/images/svg/checklist.svg"),
    };
  },
  mounted() {
    core.SmoothScrollbar();
    if (localStorage.getItem("user-details") !== null) {
      this.userDetails = JSON.parse(localStorage.getItem("user-details"));
    }
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get("sidebar");
    if (sidebar !== null) {
      this.variant = "";
      switch (sidebar) {
        case "0":
          this.variant = "sidebar-dark";
          break;
        case "1":
          this.variant = "sidebar-light";
          break;
        default:
          this.variant = "";
          break;
      }
    }
  },
  destroyed() {
    core.SmoothScrollbar();
  },
  computed: {
    ...mapGetters({
      logo: "darklogo",
      sidelogo: "logo",
    }),
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
      if (route.includes(this.$route.name)) {
        return true;
      }
    },
    miniSidebar() {
      core.triggerSet();
    },
    showCollapse(collapseId) {
      document.getElementById(collapseId).closest("li").classList.add("active");
    },
    hideCollapse() {
      const list = document
        .querySelector(".iq-sidebar-menu")
        .querySelectorAll(".submenu");
      Array.from(list, (elem) => {
        if (
          elem.closest(".active") !== null &&
          elem.closest(".active") !== undefined
        ) {
          elem.closest(".active").classList.remove("active");
        }
      });
    },
  },
};
</script>
<style scoped>
.iq-sidebar-menu .side-menu li li.active a {
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 5px;
}
</style>

